import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import '../style/AdminPanel.css'; // Assuming the styles are in this file

const BrandSplit = () => {
  return (
    <div className="admin-panel">
      <h2>Brand Split</h2>
      <div className="button-grid">
        {/* Wrapping each button in a Link to add navigation */}
        <Link to="/brandsplit/10-orders">
          <button className="panel-button">10 Orders</button>
        </Link>
        <Link to="/brandsplit/10-30-orders">
          <button className="panel-button">10-30 Orders</button>
        </Link>
        <Link to="/brandsplit/30-50-orders">
          <button className="panel-button">30-50 Orders</button>
        </Link>
        <Link to="/brandsplit/50-100-orders">
          <button className="panel-button">50-100 Orders</button>
        </Link>
        <Link to="/brandsplit/100-plus-orders">
          <button className="panel-button">100+ Orders</button>
        </Link>
      </div>
    </div>
  );
};

export default BrandSplit;
