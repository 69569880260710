import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';

const BrandWiseSellingPrice = () => {
    const [totals, setTotals] = useState({});
    const [loading, setLoading] = useState(true);
    const [accountInfo, setAccountInfo] = useState({});

    // Function to calculate total for each brand
    // Function to calculate total for each brand
    const fetchBrandWiseTotals = async () => {
        try {
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const brandTotals = {}; // Object to hold totals for each brand
            const brandAccountInfo = {};
    
            for (const userDoc of usersSnapshot.docs) {
                const userId = userDoc.id;
                const userData = userDoc.data();
                const brandName = userData.brandName || 'Unknown Brand';
                // const emailAddress = userData.emailAddress || 'Unknown Brand';
    
                const { ifscCode, branch, accountNumber, phoneNumber, name } = userData;
    
                // Fetch orders with payment_method 'COD' and status 'confirmed' for each shop
                const ordersSnapshot = await getDocs(
                    query(
                        collection(db, 'users', userId, 'orders'),
                        where('payment_method', '==', 'COD'),
                        where('status', '==', 'confirmed'),
                        where('currentStatus', '==', 'Delivered')
                    )
                );
    
                console.log(`User ${brandName} (ID: ${userId}) has ${ordersSnapshot.size} orders`);
    
                let totalSellingPrice = 0; // Accumulator for total selling price for each brand
                const deliveredDates = [];
                const codRemittanceDates = []; // Array to store COD Remittance Dates
                
                // Loop through each order in the shop
                ordersSnapshot.forEach(orderDoc => {
                    const orderData = orderDoc.data();
                    const orderSubtotal = orderData.sub_total || 0;
                    const deliveredDate = orderData.deliveredDate;
    
                    // Ensure orderSubtotal is a number and handle any formatting issues
                    const numericSubtotal = parseFloat(orderSubtotal.replace(/[^\d.-]/g, ''));
    
                    console.log(`Order ID: ${orderDoc.id}, Subtotal: ₹${orderSubtotal}, Numeric Subtotal: ₹${numericSubtotal}`);
    
                    if (numericSubtotal > 0) {
                        totalSellingPrice += numericSubtotal; // Accumulate subtotal for each order
                    } else {
                        console.log(`Invalid or missing subtotal for Order ID: ${orderDoc.id}`);
                    }
    
                    if (deliveredDate) {
                        // Parse the string into a Date object
                        const parsedDate = new Date(deliveredDate);
    
                        // Add 7 days to the deliveredDate to calculate the COD Remittance Date
                        const remittanceDate = new Date(parsedDate);
                        remittanceDate.setDate(remittanceDate.getDate() + 3); // Add 3 days
    
                        deliveredDates.push(parsedDate.toLocaleDateString()); // Store original delivered date
                        codRemittanceDates.push(remittanceDate.toLocaleDateString()); // Store remittance date
                    }
                });
    
                // After processing all orders for this shop, update brandTotals
                if (totalSellingPrice > 0) {
                    brandTotals[brandName] = (brandTotals[brandName] ?? 0) + totalSellingPrice;
    
                    brandAccountInfo[brandName] = {
                        ifscCode,
                        branch,
                        accountNumber,
                        phoneNumber,
                        name,
                        deliveredDates,
                        codRemittanceDates // Add COD Remittance Dates to the account info
                    };
                } else {
                    console.log(`No valid orders for ${brandName}`);
                }
            }
    
            console.log('Brand-wise totals:', brandTotals);
    
            // Set the calculated totals to state
            setTotals(brandTotals);
            setAccountInfo(brandAccountInfo);
            setLoading(false); // Update loading state
        } catch (error) {
            console.error('Error fetching brand-wise totals:', error);
            toast.error('Failed to load brand-wise totals');
            setLoading(false);
        }
    };
   


    useEffect(() => {
        fetchBrandWiseTotals(); // Fetch brand-wise totals on component mount
    }, []);


const getStartAndEndOfWeek = (date) => {
    const day = date.getDay(),
        diff = date.getDate() - day + (day == 0 ? -6 : 1); // Adjust for Sunday as the start of the week
    const startOfWeek = new Date(date.setDate(diff));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    return {
        startOfWeek: startOfWeek.toISOString().split('T')[0], // Format as YYYY-MM-DD
        endOfWeek: endOfWeek.toISOString().split('T')[0], // Format as YYYY-MM-DD
    };
};




// Function to calculate total for each brand, grouped by week
// const fetchBrandWiseTotals = async () => {
//     try {
//         const shopsSnapshot = await getDocs(collection(db, 'shops'));
//         const brandTotals = {}; // Object to hold totals for each brand per week
//         const brandAccountInfo = {}; // Object to hold account info for each brand

//         for (const shopDoc of shopsSnapshot.docs) {
//             const shopId = shopDoc.id;
//             const shopData = shopDoc.data();
//             const brandName = shopData.brandName || 'Unknown Brand';

//             const { ifscCode, branch, accountNumber, phoneNumber, name } = shopData;

//             // Fetch orders with payment_method 'COD' and status 'confirmed' for each shop
//             const ordersSnapshot = await getDocs(
//                 query(
//                     collection(db, 'shops', shopId, 'orders'),
//                     where('payment_method', '==', 'COD'),
//                     where('status', '==', 'confirmed')
//                 )
//             );

//             let brandWeekTotals = {}; // To store totals per week for each brand

//             // Loop through each order in the shop
//             ordersSnapshot.forEach(orderDoc => {
//                 const orderData = orderDoc.data();
//                 const orderSubtotal = orderData.sub_total || 0;
//                 const updatedAt = orderData.updatedAt?.toDate(); // Firebase timestamp to JS Date

//                 if (updatedAt && orderSubtotal) {
//                     const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(updatedAt); // Get week range
//                     const weekKey = `${startOfWeek} - ${endOfWeek}`; // Week identifier

//                     // Ensure orderSubtotal is a valid number
//                     const numericSubtotal = parseFloat(orderSubtotal.replace(/[^\d.-]/g, ''));

//                     if (numericSubtotal > 0) {
//                         if (!brandWeekTotals[weekKey]) {
//                             brandWeekTotals[weekKey] = 0;
//                         }
//                         brandWeekTotals[weekKey] += numericSubtotal; // Accumulate subtotal for the week
//                     }
//                 }
//             });

//             // Store the totals and account information
//             if (Object.keys(brandWeekTotals).length > 0) {
//                 brandTotals[brandName] = brandWeekTotals;
//                 brandAccountInfo[brandName] = {
//                     ifscCode,
//                     branch,
//                     accountNumber,
//                     phoneNumber,
//                     name
//                 };
//             }
//         }

//         setTotals(brandTotals);
//         setAccountInfo(brandAccountInfo);
//         setLoading(false);
//     } catch (error) {
//         console.error('Error fetching brand-wise totals:', error);
//         toast.error('Failed to load brand-wise totals');
//         setLoading(false);
//     }
// };

// Rendering the table to show totals per week for each brand


    // API call to create payout request for the brand
    const createPayoutRequest = async (brandName, totalAmount) => {
        try {
            const accountDetails = accountInfo[brandName];
            const response = await fetch('https://ufb-1.onrender.com/api/create-payout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    brandName,
                    totalAmount, // In INR
                    accountInfo: {
                        phoneNumber: accountDetails.phoneNumber,
                        ifscCode: accountDetails.ifscCode,
                        branch: accountDetails.branch,
                        accountNumber: accountDetails.accountNumber,
                        name: accountDetails.name,
                    },
                }),
            });

            const data = await response.json();
            if (data.success) {
                toast.success('Payout initiated successfully');
            } else {
                toast.error(`Error: ${data.message}`);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to initiate payout');
        }
    };


    const createBulkPayoutRequest = async () => {
        const payouts = Object.entries(totals)
            .filter(([brandName, totalAmount]) => totalAmount > 0)
            .map(([brandName, totalAmount]) => {
                const accountDetails = accountInfo[brandName];
                return {
                    brandName,
                    totalAmount, // In INR
                    accountInfo: {
                        phoneNumber: accountDetails.phoneNumber,
                        ifscCode: accountDetails.ifscCode,
                        branch: accountDetails.branch,
                        accountNumber: accountDetails.accountNumber,
                        name: accountDetails.name,
                    },
                };
            });
    
        console.log("Sending the following payouts to backend:", payouts);
    
        try {
            const response = await fetch('https://ufb-1.onrender.com/api/create-bulk-payout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ payouts }),
            });
    
            const data = await response.json();
            console.log("Backend response:", data);
    
            if (data.success) {
                toast.success('Bulk payout initiated successfully');
            } else {
                toast.error(`Error: ${data.message}`);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to initiate bulk payout');
        }
    };
    
    
    return (
        <div className="brand-wise-totals">
            <h3>Brand-wise Total Selling Price</h3>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <table className="totals-table">
                    <thead>
                        <tr>
                            <th>Brand Name</th>
                            <th>Total Selling Price (₹)</th>
                            <th>IFSC Code</th>
                            <th>Branch</th>
                            <th>Account Number</th>
                            <th>Phone Number</th>
                            <th>Delivered Dates</th> 
                            <th>COD Remittance Date</th>
                            <th>Action</th> {/* Add action column for payout */}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(totals).map(([brand, total]) => (
                            <tr key={brand}>
                                <td>{brand}</td>
                                <td>₹{total}</td>
                                <td>{accountInfo[brand]?.ifscCode || 'N/A'}</td>
                                <td>{accountInfo[brand]?.branch || 'N/A'}</td>
                                <td>{accountInfo[brand]?.accountNumber || 'N/A'}</td>
                                <td>{accountInfo[brand]?.phoneNumber || 'N/A'}</td>
                                <td>
                                    {/* Display delivered dates if available */}
                                    {accountInfo[brand]?.deliveredDates?.join(', ') || 'N/A'}
                                </td>
                                <td>
                        {/* Display COD Remittance Dates if available */}
                        {accountInfo[brand]?.codRemittanceDates?.join(', ') || 'N/A'}
                    </td>
                                <td>
                                    <button
                                        onClick={() => createPayoutRequest(brand, total)}
                                        disabled={total <= 0}
                                    >
                                        Create Payout
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>


                    {/* <tbody>
    {Object.entries(totals).map(([brand, weeks]) =>
        Object.entries(weeks).map(([week, total]) => (
            <tr key={`${brand}-${week}`}>
                <td>{brand}</td>
                <td>{week}</td>
                <td>₹{total}</td>
                <td>{accountInfo[brand]?.ifscCode || 'N/A'}</td>
                <td>{accountInfo[brand]?.branch || 'N/A'}</td>
                <td>{accountInfo[brand]?.accountNumber || 'N/A'}</td>
                <td>{accountInfo[brand]?.phoneNumber || 'N/A'}</td>
                <td>
                    <button
                        onClick={() => createPayoutRequest(brand, total)}
                        disabled={total <= 0}
                    >
                        Create Payout
                    </button>
                </td>
            </tr>
        ))
    )}
</tbody> */}
                </table>
            )}
                        <button onClick={createBulkPayoutRequest}>Create Bulk Payout</button>
            <ToastContainer />
        </div>
    );
};

export default BrandWiseSellingPrice;
