import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import '../style/CustomerSupport.css';

const CustomerSupport = () => {
  const [contactMessages, setContactMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all shops and their contact messages
  useEffect(() => {
    const fetchUsersAndMessages = async () => {
      try {
        // Fetch all shops
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const usersList = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setUsers(usersList);

        // Fetch all contact messages for each shop
        const messagesList = [];
        for (const user of usersList) {
          const contactMessagesSnapshot = await getDocs(
            collection(db, 'users', user.id, 'contactMessages')
          );
          contactMessagesSnapshot.forEach(doc => {
            messagesList.push({
              id: doc.id,
              userId: user.id,
              userName: user.brandName,
              EmailAddress: user.emailAddress,
              PhoneNumber: user.phoneNumber, // Assuming 'name' is a field in your shop document
              ...doc.data(),
            });
          });
        }

        setContactMessages(messagesList);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsersAndMessages();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="customer-support">
      <h1>Customer Support</h1>

      {/* Table to display contact messages from all shops */}
      <table className="contact-messages-table">
        <thead>
          <tr>
            <th>Brand Name</th>
            <th>Message</th>
            <th>Image</th>
            <th>Video</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {contactMessages.map((message) => (
            <tr key={message.id}>
              <td>{message.userName}</td>
              <td>{message.message}</td>
              <td>
                {message.imageUrl ? (
                  <a href={message.imageUrl} target="_blank" rel="noopener noreferrer">
                    View Image
                  </a>
                ) : (
                  'No image'
                )}
              </td>
              <td>
                {message.videoUrl ? (
                  <a href={message.videoUrl} target="_blank" rel="noopener noreferrer">
                    View Video
                  </a>
                ) : (
                  'No video'
                )}
              </td>
              <td>{message.EmailAddress}</td>
              <td>{message.PhoneNumber}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerSupport;
