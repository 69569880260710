import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import to match your Firebase configuration
import '../style/Designs.css'; // Ensure this file has the styles

const Designs = () => {
  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [brandName, setBrandName] = useState('');
  const [brands, setBrands] = useState([]); // Store unique brand names

  const itemsPerPage = 5;

  // Fetch the designs and brands from Firestore
  useEffect(() => {
    const fetchDesigns = async () => {
      setLoading(true);
      try {
        // Fetch all shops and brands
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const allDesigns = [];
        const allBrands = new Set();

        usersSnapshot.forEach(userDoc => {
          const userData = userDoc.data();
          const brand = userData.brandName || 'N/A';
          allBrands.add(brand);

          // Fetch designs from the 'designs' subcollection for each shop
          getDocs(collection(db, 'users', userDoc.id, 'designs')).then(designsSnapshot => {
            designsSnapshot.forEach(doc => {
              const designData = doc.data();
              allDesigns.push({
                designId: doc.id,
                ...designData,
                userId: userDoc.id,
                brandName: brand, // Add brand name to design data
              });
            });
            setDesigns(allDesigns);
            setBrands([...allBrands]); // Update unique brands list
            setLoading(false);
            setTotalPages(Math.ceil(allDesigns.length / itemsPerPage)); // Calculate total pages based on all designs
          });
        });
      } catch (error) {
        console.error("Error fetching designs:", error);
        setLoading(false);
      }
    };

    fetchDesigns();
  }, []); // Only run once on initial load

  // Filter designs by brand
  const filteredDesigns = designs.filter(design => {
    return brandName === '' || design.brandName === brandName;
  });

  // Paginate filtered designs
  const paginatedDesigns = filteredDesigns.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  if (loading) {
    return <p>Loading designs...</p>;
  }

  return (
    <div className="designs-page">
      <h1>Designs</h1>
      
      {/* Brand Filter Dropdown */}
      <div className="filter-container">
        <label htmlFor="brand-filter">Filter by Brand: </label>
        <select id="brand-filter" value={brandName} onChange={(e) => setBrandName(e.target.value)}>
          <option value="">All Brands</option>
          {brands.map((brand, index) => (
            <option key={index} value={brand}>{brand}</option>
          ))}
        </select>
      </div>

      {/* Designs Table */}
      <table className="designs-table">
        <thead>
          <tr>
            <th>Brand Name</th>
            <th>Design Name</th>
            <th>Print Cost</th>
            <th>Base Cost</th>
            <th>Front Design</th>
            <th>Back Design</th>
            <th>Total Price</th>
            <th>Product Name</th>
            <th>Product GSM</th>
          </tr>
        </thead>
        <tbody>
          {paginatedDesigns.map((design, index) => (
            <tr key={index}>
              <td>{design.brandName}</td>
              <td>{design.designName || 'N/A'}</td>
              <td>{design.PrintCost || 'N/A'}</td>
              <td>{design.baseCost || 'N/A'}</td>
              <td>
                {design.frontDesign ? (
                  <img src={design.frontDesign} alt="Front Design" style={{ width: '100px', height: 'auto' }} />
                ) : 'N/A'}
              </td>
              <td>
                {design.backDesign ? (
                  <img src={design.backDesign} alt="Back Design" style={{ width: '100px', height: 'auto' }} />
                ) : 'N/A'}
              </td>
              <td>{design.totalPrice || 'N/A'}</td>
              <td>{design.productName || 'N/A'}</td>
              <td>{design.productGSM || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={page === 1}>Previous</button>
        <span>Page {page} of {totalPages}</span>
        <button onClick={handleNextPage} disabled={page === totalPages}>Next</button>
      </div>
    </div>
  );
};

export default Designs;



// import React, { useEffect, useState } from 'react';
// import { collection, getDocs, query, orderBy, limit, startAfter } from 'firebase/firestore';
// import { db } from '../firebase'; // Adjust the import to match your Firebase configuration
// import '../style/Designs.css';

// const Designs = () => {
//   const [designs, setDesigns] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [lastVisible, setLastVisible] = useState(null); // Track the last visible document
//   const [hasMore, setHasMore] = useState(true); // To determine if there are more designs to load
//   const [page, setPage] = useState(1); // Page state
//   const designsPerPage = 5; // Number of designs per page

//   useEffect(() => {
//     const fetchDesigns = async () => {
//       setLoading(true); // Set loading to true before fetching data
//       try {
//         const allDesigns = [];
//         let lastVisibleDoc = lastVisible; // Start with the current lastVisible state

//         // Query the users collection
//         const usersSnapshot = await getDocs(collection(db, 'users'));

//         // Iterate through each user to fetch their designs
//         for (const userDoc of usersSnapshot.docs) {
//           const userId = userDoc.id;

//           // Query the 'designs' subcollection for each user
//           let designsQuery = query(
//             collection(db, 'users', userId, 'designs'),
//             orderBy('designName'),
//             limit(designsPerPage)
//           );

//           // If it's not the first page, use startAfter to paginate
//           if (lastVisibleDoc) {
//             designsQuery = query(
//               collection(db, 'users', userId, 'designs'),
//               orderBy('designName'),
//               startAfter(lastVisibleDoc), // Start after the last fetched document
//               limit(designsPerPage)
//             );
//           }

//           // Fetch the designs for each user
//           const designsSnapshot = await getDocs(designsQuery);

//           // Add designs to the allDesigns array
//           designsSnapshot.forEach(doc => {
//             const designData = doc.data();
//             allDesigns.push({
//               designId: doc.id,
//               ...designData,
//               userId,
//             });
//           });

//           // Set the last document to be used for pagination (after all users are fetched)
//           if (designsSnapshot.docs.length > 0) {
//             lastVisibleDoc = designsSnapshot.docs[designsSnapshot.docs.length - 1];
//           }
//         }

//         // Update the state with the fetched designs
//         setDesigns(allDesigns);
//         setLastVisible(lastVisibleDoc); // Set the last document for pagination
//         setHasMore(allDesigns.length === designsPerPage); // Check if there are more designs to load
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching designs:", error);
//         setLoading(false);
//       }
//     };

//     fetchDesigns();
//   }, [page]); // Fetch designs whenever the page state changes

//   const handleNextPage = () => {
//     if (hasMore) {
//       setPage(prevPage => prevPage + 1); // Increment the page to load the next set of designs
//     }
//   };

//   const handlePreviousPage = () => {
//     if (page > 1) {
//       setPage(prevPage => prevPage - 1); // Decrement the page to load the previous set of designs
//     }
//   };

//   if (loading) {
//     return <p>Loading designs...</p>;
//   }

//   return (
//     <div className="designs-page">
//       <h1>Designs</h1>
//       <table className="designs-table">
//         <thead>
//           <tr>
//             <th>Design ID</th>
//             <th>Design Name</th>
//             <th>Print Cost</th>
//             <th>Base Cost</th>
//             <th>Front Design</th>
//             <th>Back Design</th>
//             <th>Total Price</th>
//             <th>Product Name</th>
//             <th>Product GSM</th>
//           </tr>
//         </thead>
//         <tbody>
//           {designs.map((design, index) => (
//             <tr key={index}>
//               <td>{design.designId}</td>
//               <td>{design.designName || 'N/A'}</td>
//               <td>{design.PrintCost || 'N/A'}</td>
//               <td>{design.baseCost || 'N/A'}</td>
//               <td>
//                 {design.frontDesign ? (
//                   <img src={design.frontDesign} alt="Front Design" style={{ width: '100px', height: 'auto' }} />
//                 ) : 'N/A'}
//               </td>
//               <td>
//                 {design.backDesign ? (
//                   <img src={design.backDesign} alt="Back Design" style={{ width: '100px', height: 'auto' }} />
//                 ) : 'N/A'}
//               </td>
//               <td>{design.totalPrice || 'N/A'}</td>
//               <td>{design.productName || 'N/A'}</td>
//               <td>{design.productGSM || 'N/A'}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <div className="pagination">
//         <button onClick={handlePreviousPage} disabled={page === 1}>Previous</button>
//         <span>Page {page}</span>
//         <button onClick={handleNextPage} disabled={!hasMore}>Next</button>
//       </div>
//     </div>
//   );
// };

// export default Designs;
