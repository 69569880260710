import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import to match your Firebase configuration
import '../style/Adminorders.css';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const CodRemittance = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query

    // Cache for SKU price to avoid redundant fetching
    const skuPriceCache = {};

    // Fetch design details by SKU
    const fetchDesignDetailsBySKU = async (sku, userId) => {
        if (!sku) return null;

        // Check cache first
        if (skuPriceCache[sku]) {
            return skuPriceCache[sku];
        }

        try {
            const designsCollection = collection(db, 'users', userId, 'designs');
            const q = query(designsCollection, where('SKUs', 'array-contains', sku));
            const designSnapshot = await getDocs(q);

            if (!designSnapshot.empty) {
                const designData = designSnapshot.docs[0].data();
                skuPriceCache[sku] = designData.totalPrice;
                return designData.totalPrice;
            }
            return null;
        } catch (error) {
            console.error("Error fetching design details by SKU:", error);
            return null;
        }
    };


    const formatDate = (timestamp) => {
        if (!timestamp) return '';
        const date = timestamp.toDate(); // Convert Firestore Timestamp to Date object
        return date.toLocaleDateString(); // You can customize this format if needed
    };

    // Fetch all orders from all shops
    const fetchOrders = async () => {
        try {
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const allOrders = [];
    
            // Iterate through each shop and fetch their orders
            for (const userDoc of usersSnapshot.docs) {
                const userId = userDoc.id;
                const userData = userDoc.data();
                const brandName = userData.brandName || 'Unknown Brand';
    
                // Fetch orders within each shop's orders collection with payment_method 'COD' and status 'Confirmed'
                const ordersSnapshot = await getDocs(
                    query(
                        collection(db, 'users', userId, 'orders'),
                        where('payment_method', '==', 'COD'),
                        where('status', '==', 'confirmed'),
                        where('currentStatus', '==', 'Delivered' )
                    )
                );
    
                for (const orderDoc of ordersSnapshot.docs) {
                    const orderData = orderDoc.data();
                    const orderItems = orderData.order_items || [];
    
                    let totalCost = 0;
    
                    // Fetch total price for each item in the order
                    for (const item of orderItems) {
                        const designPrice = await fetchDesignDetailsBySKU(item.sku, userId);
                        if (designPrice) {
                            totalCost += designPrice;
                        }
                    }
    
                    allOrders.push({
                        id: orderDoc.id,
                        brandName,
                        totalCost, // Calculate total cost for the order
                        shippingCost: orderData.shippingCost, // Assuming a default shipping cost
                        status: orderData.status || 'pending',
                        Paymentmethod: orderData.payment_method, // Store the payment method
                        Sellingprice: orderData.sub_total,
                        OrderDate: orderData.createdAt
                    });
                }
            }
    
            setOrders(allOrders);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            toast.error('Failed to load orders');
            setLoading(false);
        }
    };
    

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filter orders based on the search query
    const filteredOrders = orders.filter(order =>
        order.id.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <div className="admin-orders-page">            
            {/* Search input */}
           <h3> COD Remittance</h3>
           <div className="button-grid">
        <Link to="/brand-wise-sellingPrice">
          <button className="panel-button">Brand wise selling price</button>
        </Link>
        </div>
            {/* <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by Order ID"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
            </div> */}

            {loading ? (
                <p>Loading orders...</p>
            ) : (
                <table className="admin-orders-table">
                    <thead>
                        <tr>
                            <th>Brand Name</th>
                            <th>Order No</th>
                            <th>Selling price</th>
                            <th>Status</th>
                            <th>Payment Method</th>
                            <th>Order Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrders.map(order => (
                            <tr key={order.id}>
                                <td>{order.brandName}</td>
                                <td>{order.id}</td>
                                <td>₹{order.Sellingprice}</td> {/* Display totalCost */}
                                <td>{order.status}</td>
                                <td>{order.Paymentmethod}</td>
                               <td>{formatDate(order.OrderDate)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <ToastContainer />
        </div>
    );
};

export default CodRemittance;