
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminPanel from './pages/AdminPanel';
import Trackgrowth from './pages/Trackgrowth';
import OngoingOrders from './pages/OngoingOrders';
import SalesInvoice from './pages/SalesInvoice';
import Transaction from './pages/Transaction';
import CustomerSupport from './pages/CustomerSupport';
import Inventory from './pages/Inventory';
import Aov from './pages/Aov';
import Bestsellers from './pages/Bestsellers';
import BrandActivity from './pages/BrandActivity';
import UnresolvedProbelem from './pages/UnresolvedProbelem';
import BrandSplit from './pages/BrandSplit'
import RevenueGrowth from './pages/RevenueGrowth'
import Designs from './pages/Designs';
import CodRemittance from './pages/CodRemittance';
import BrandWiseSellingPrice from './pages/BWsellingPrice';
import VendorPayments from './pages/VendorPayments';
import RTOcharges from './pages/RTOcharges';
import LoginPage from './pages/Login';
import AuthRoute from './components/AuthRoute';

function App() {
  return (
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<LoginPage/>} />
          <Route path="/Dashboard" element={<AuthRoute element={AdminPanel} />} />
          <Route path="/trackgrowth" element={<AuthRoute element={Trackgrowth} />} />
<Route path="/trackgrowth/average-order-value" element={<AuthRoute element={Aov} />} />
<Route path="/trackgrowth/best-sellers" element={<AuthRoute element={Bestsellers} />} />
<Route path="/trackgrowth/brand-activity" element={<AuthRoute element={BrandActivity} />} />
<Route path="/trackgrowth/revenue-growth" element={<AuthRoute element={RevenueGrowth} />} />
<Route path="/trackgrowth/brand-split" element={<AuthRoute element={BrandSplit} />} />
<Route path="/trackgrowth/unresolved-problem" element={<AuthRoute element={UnresolvedProbelem} />} />
<Route path="/ongoing-orders" element={<AuthRoute element={OngoingOrders} />} />
<Route path="/Sales-invoices" element={<AuthRoute element={SalesInvoice} />} />
<Route path="/Transaction" element={<AuthRoute element={Transaction} />} />
<Route path="/Customer-support" element={<AuthRoute element={CustomerSupport} />} />
<Route path="/inventory" element={<AuthRoute element={Inventory} />} />
<Route path="/cod-remittance" element={<AuthRoute element={CodRemittance} />} />
<Route path="/designs" element={<AuthRoute element={Designs} />} />
<Route path="/brand-wise-sellingPrice" element={<AuthRoute element={BrandWiseSellingPrice} />} />
<Route path="/vendor-payments" element={<AuthRoute element={VendorPayments} />} />
<Route path="/rto-charges" element={<AuthRoute element={RTOcharges} />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
