import React, { useState } from 'react';
import { auth } from '../firebase'; // Import your Firebase config
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // Import useHistory for redirection

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Initialize useHistory

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            // Allowed email
            const allowedEmail = 'rajprit@uniteeculture.com';

            if (email !== allowedEmail) {
                throw new Error('You are not authorized to access this application.');
            }

            await signInWithEmailAndPassword(auth, email, password);
            // Redirect to the order page on successful login
            alert('Login successful!');
            navigate('/Dashboard'); // Change to your order page route
        } catch (error) {
            setError(error.code === 'auth/wrong-password' ? 'Incorrect password.' : error.message);
        } finally {
            setLoading(false);
        }
    };

    // Inline style objects
    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f4f7fc',
            margin: 0,
            padding: 0,
        },
        formContainer: {
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '30px',
            width: '100%',
            maxWidth: '400px',
        },
        heading: {
            textAlign: 'center',
            marginBottom: '20px',
            color: '#333',
        },
        label: {
            fontSize: '14px',
            color: '#555',
            marginBottom: '5px',
            display: 'block',
        },
        input: {
            width: '100%',
            padding: '12px',
            margin: '10px 0',
            borderRadius: '4px',
            border: '1px solid #ccc',
            fontSize: '14px',
            color: '#333',
        },
        button: {
            width: '100%',
            padding: '12px',
            backgroundColor: '#000',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            fontSize: '16px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        buttonDisabled: {
            width: '100%',
            padding: '12px',
            backgroundColor: '#ccc',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            fontSize: '16px',
            cursor: 'not-allowed',
        },
        errorMessage: {
            color: '#e74c3c',
            fontSize: '14px',
            marginTop: '10px',
            textAlign: 'center',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.formContainer}>
                <h2 style={styles.heading}>Login</h2>
                <form onSubmit={handleLogin}>
                    <div>
                        <label style={styles.label}>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            style={styles.input}
                        />
                    </div>
                    <div>
                        <label style={styles.label}>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            style={styles.input}
                        />
                    </div>
                    {error && <p style={styles.errorMessage}>{error}</p>}
                    <button
                        type="submit"
                        disabled={loading}
                        style={loading ? styles.buttonDisabled : styles.button}
                    >
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
