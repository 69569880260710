import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebase'; // Import your Firebase config

const AuthRoute = ({ element: Component, ...rest }) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
            setLoading(false); // Stop loading once we have the authentication state
        });

        return () => unsubscribe(); // Cleanup the subscription when component unmounts
    }, []);

    if (loading) {
        return <div>Loading...</div>; // You can show a loading spinner here if needed
    }

    return user ? (
        <Component {...rest} />
    ) : (
        <Navigate to="/" replace /> // Redirect to login if not authenticated
    );
};

export default AuthRoute;

