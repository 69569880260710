import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import to match your Firebase configuration
import '../style/Adminorders.css';
import { ToastContainer, toast } from 'react-toastify';

const RTOcharges = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query

    // Fetch RTO initiated orders from all shops
    const fetchRTOOrders = async () => {
        try {
            const shopsSnapshot = await getDocs(collection(db, 'shops'));
            const allRTOOrders = [];

            for (const shopDoc of shopsSnapshot.docs) {
                const shopId = shopDoc.id;
                const shopData = shopDoc.data();
                const brandName = shopData.brandName || 'Unknown Brand';

                const ordersSnapshot = await getDocs(
                    query(collection(db, 'shops', shopId, 'orders'), where('currentStatus', '==', 'RTO Initiated'))
                );

                for (const orderDoc of ordersSnapshot.docs) {
                    const orderData = orderDoc.data();

                    if (orderData.currentStatus === 'RTO Initiated') {
                        allRTOOrders.push({
                            id: orderDoc.id,
                            brandName,
                            shopId,
                            rtoInDate: orderData.RTOinDate || 'N/A', // Store RTOinDate or 'N/A' if not available
                        });
                    }
                }
            }

            setOrders(allRTOOrders);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching RTO orders:', error);
            toast.error('Failed to load RTO orders');
            setLoading(false);
        }
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filter RTO orders based on the search query
    const filteredOrders = orders.filter(order =>
        order.id.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        fetchRTOOrders();
    }, []);

    return (
        <div className="admin-orders-page">
            <h1>RTO Orders</h1>

            {/* Search input */}
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by Order ID"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
            </div>

            {loading ? (
                <p>Loading RTO orders...</p>
            ) : (
                <table className="admin-orders-table">
                    <thead>
                        <tr>
                            <th>Brand Name</th>
                            <th>Order No</th>
                            <th>Shop ID</th>
                            <th>RTO Initiated Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrders.map(order => (
                            <tr key={order.id}>
                                <td>{order.brandName}</td>
                                <td>{order.id}</td>
                                <td>{order.shopId}</td>
                                <td>{order.rtoInDate}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <ToastContainer />
        </div>
    );
};

export default RTOcharges;
