// import React, { useEffect, useState } from 'react';
// import { collection, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
// import { db } from '../firebase'; // Adjust the import to match your Firebase configuration
// import '../style/Adminorders.css';
// import { ToastContainer, toast } from 'react-toastify';
// import axios from 'axios';

// const OngoingOrders = () => {
//     const [orders, setOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchQuery, setSearchQuery] = useState(''); // State for search query
//     const [brandFilter, setBrandFilter] = useState(''); // State for brand filter (Dropdown value)
//     const [allBrands, setAllBrands] = useState([]); // State to hold all unique brand names
//     const [trackingUrls, setTrackingUrls] = useState({}); // State to store tracking URLs
//     const [currentStatus, setCurrentStatus] = useState({});
//     const [deliveredDates, setDeliveredDates] = useState({});
    
//     // Pagination states
//     const [currentPage, setCurrentPage] = useState(1); // Tracks current page
//     const [itemsPerPage] = useState(10); // Number of orders per page

//     const skuPriceCache = {};

//     const fetchDesignDetailsBySKU = async (sku, userId) => {
//         if (!sku) return null;

//         if (skuPriceCache[sku]) {
//             return skuPriceCache[sku];
//         }

//         try {
//             const designsCollection = collection(db, 'users', userId, 'designs');
//             const q = query(designsCollection, where('SKUs', 'array-contains', sku));
//             const designSnapshot = await getDocs(q);

//             if (!designSnapshot.empty) {
//                 const designData = designSnapshot.docs[0].data();
//                 skuPriceCache[sku] = designData.totalPrice;
//                 return designData.totalPrice;
//             }
//             return null;
//         } catch (error) {
//             console.error("Error fetching design details by SKU:", error);
//             return null;
//         }
//     };

//     const fetchTrackingData = async (orderId) => {
//         try {
//             const response = await axios.get(`http://localhost:8080/api/ongoing-track-order?order_id=${orderId}`);
//             if (response.data) {
//                 return {
//                     trackingUrl: response.data.trackingUrl,
//                     currentStatus: response.data.currentStatus,
//                     deliveredDate: response.data.deliveredDate,
//                 };
//             } else {
//                 console.error("No tracking data found in response");
//                 return null;
//             }
//         } catch (error) {
//             console.error("Error fetching tracking URL for order", orderId, error);
//             return null;
//         }
//     };

//     const updateOrderStatusInFirebase = async (orderId, userId, deliveredDate) => {
//         try {
//             const orderRef = doc(db, 'users', userId, 'orders', orderId);
//             await updateDoc(orderRef, {
//                 deliveredDate: deliveredDate,
//                 currentStatus: 'Delivered',
//             });
//             console.log('Order status updated to delivered in Firebase');
//         } catch (error) {
//             console.error('Error updating order in Firebase', error);
//         }
//     };

//     const updateRTOStatusInFirebase = async (orderId, userId) => {
//         try {
//             const orderRef = doc(db, 'users', userId, 'orders', orderId);
//             const currentDate = new Date();

//             await updateDoc(orderRef, {
//                 RTOinDate: currentDate,
//                 currentStatus: 'RTO INITIATED',
//             });
//             console.log('Order status updated to RTO INITIATED in Firebase');
//         } catch (error) {
//             console.error('Error updating order in Firebase', error);
//         }
//     };

//     const fetchOrders = async () => {
//         try {
//             const usersSnapshot = await getDocs(collection(db, 'users'));
//             const allOrders = [];
//             const brandsSet = new Set(); // To hold unique brands

//             for (const userDoc of usersSnapshot.docs) {
//                 const userId = userDoc.id;
//                 const userData = userDoc.data();
//                 const brandName = userData.brandName || 'Unknown Brand';

//                 // Add the brand name to the set for uniqueness
//                 brandsSet.add(brandName);

//                 const ordersSnapshot = await getDocs(collection(db, 'users', userId, 'orders'));

//                 for (const orderDoc of ordersSnapshot.docs) {
//                     const orderData = orderDoc.data();
//                     const orderItems = orderData.order_items || [];

//                     if (orderData.status !== 'confirmed') {
//                         continue;
//                     }

//                     let totalCost = 0;

//                     for (const item of orderItems) {
//                         const designPrice = await fetchDesignDetailsBySKU(item.sku, userId);
//                         if (designPrice) {
//                             totalCost += designPrice;
//                         }
//                     }

//                     allOrders.push({
//                         id: orderDoc.id,
//                         brandName,
//                         totalCost,
//                         shippingCost: orderData.shippingCost,
//                         status: orderData.status || 'pending',
//                         userId,
//                     });
//                 }
//             }

//             // Convert the brands set into an array and set the brand options
//             setAllBrands(Array.from(brandsSet));

//             setOrders(allOrders);

//             const newTrackingUrls = {};
//             const newCurrentStatus = {};
//             const newDeliveredDates = {};

//             for (const order of allOrders) {
//                 const trackingData = await fetchTrackingData(order.id);
//                 if (trackingData) {
//                     newTrackingUrls[order.id] = trackingData.trackingUrl;
//                     newCurrentStatus[order.id] = trackingData.currentStatus;
//                     newDeliveredDates[order.id] = trackingData.deliveredDate;

//                     if (trackingData.currentStatus === 'Delivered') {
//                         updateOrderStatusInFirebase(order.id, order.userId, trackingData.deliveredDate);
//                     }

//                     if (trackingData.currentStatus === 'RTO Initiated') {
//                         updateRTOStatusInFirebase(order.id, order.userId);
//                     }
//                 } else {
//                     newTrackingUrls[order.id] = 'N/A';
//                     newCurrentStatus[order.id] = 'N/A';
//                     newDeliveredDates[order.id] = 'N/A';
//                 }
//             }

//             setTrackingUrls(newTrackingUrls);
//             setCurrentStatus(newCurrentStatus);
//             setDeliveredDates(newDeliveredDates);
//             setLoading(false);
//         } catch (error) {
//             console.error('Error fetching orders:', error);
//             toast.error('Failed to load orders');
//             setLoading(false);
//         }
//     };

//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//     };

//     const handleBrandFilterChange = (event) => {
//         setBrandFilter(event.target.value);
//     };

//     // Filter orders based on the search query and selected brand name
//     const filteredOrders = orders
//         .filter(order =>
//             order.id.toLowerCase().includes(searchQuery.toLowerCase()) &&
//             (brandFilter === '' || order.brandName === brandFilter) // Filter by brand name
//         );

//     // Pagination logic
//     const indexOfLastOrder = currentPage * itemsPerPage;
//     const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
//     const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

//     // Change page
//     const paginate = (pageNumber) => setCurrentPage(pageNumber);

//     // Calculate total number of pages
//     const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

//     useEffect(() => {
//         fetchOrders();
//     }, []);

//     return (
//         <div className="admin-orders-page">
//             <h1>Admin Orders</h1>

//             {/* Search input */}
//             <div className="search-container">
//                 <input
//                     type="text"
//                     placeholder="Search by Order ID"
//                     value={searchQuery}
//                     onChange={handleSearchChange}
//                     className="search-input"
//                 />
//             </div>

//             {/* Brand Name Dropdown */}
//             <div className="filter-container">
//                 <select
//                     value={brandFilter}
//                     onChange={handleBrandFilterChange}
//                     className="filter-select"
//                 >
//                     <option value="">All Brands</option>
//                     {allBrands.map(brand => (
//                         <option key={brand} value={brand}>
//                             {brand}
//                         </option>
//                     ))}
//                 </select>
//             </div>

//             {loading ? (
//                 <p>Loading orders...</p>
//             ) : (
//                 <>
//                     <table className="admin-orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Brand Name</th>
//                                 <th>Order No</th>
//                                 <th>Total Cost</th>
//                                 <th>Status</th>
//                                 <th>Tracking URL</th>
//                                 <th>Current Status</th>
//                                 <th>Delivered Date</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {currentOrders.map(order => (
//                                 <tr key={order.id}>
//                                     <td>{order.brandName}</td>
//                                     <td>{order.id}</td>
//                                     <td>₹{order.totalCost}</td>
//                                     <td>{order.status}</td>
//                                     <td>
//                                         {trackingUrls[order.id] && trackingUrls[order.id] !== 'N/A' ? (
//                                             <a href={trackingUrls[order.id]} target="_blank" rel="noopener noreferrer">
//                                                 Track Order
//                                             </a>
//                                         ) : (
//                                             <span>Not Available</span>
//                                         )}
//                                     </td>
//                                     <td>{currentStatus[order.id] || 'N/A'}</td>
//                                     <td>{deliveredDates[order.id] || 'N/A'}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>

//                     {/* Pagination Controls */}
//                     <div className="pagination">
//                         <button
//                             onClick={() => paginate(currentPage - 1)}
//                             disabled={currentPage === 1}
//                         >
//                             Previous
//                         </button>
//                         {[...Array(totalPages).keys()].map(number => (
//                             <button
//                                 key={number + 1}
//                                 onClick={() => paginate(number + 1)}
//                                 className={currentPage === number + 1 ? 'active' : ''}
//                             >
//                                 {number + 1}
//                             </button>
//                         ))}
//                         <button
//                             onClick={() => paginate(currentPage + 1)}
//                             disabled={currentPage === totalPages}
//                         >
//                             Next
//                         </button>
//                     </div>
//                 </>
//             )}
//             <ToastContainer />
//         </div>
//     );
// };

// export default OngoingOrders;





// import React, { useEffect, useState } from 'react';
// import { collection, getDocs, query, where, updateDoc, doc, limit } from 'firebase/firestore';
// import { db } from '../firebase'; // Adjust the import to match your Firebase configuration
// import '../style/Adminorders.css';
// import { ToastContainer, toast } from 'react-toastify';
// import axios from 'axios';

// const OngoingOrders = () => {
//     const [orders, setOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchQuery, setSearchQuery] = useState(''); // State for search query
//     const [brandFilter, setBrandFilter] = useState(''); // State for brand filter (Dropdown value)
//     const [allBrands, setAllBrands] = useState([]); // State to hold all unique brand names
//     const [trackingUrls, setTrackingUrls] = useState({}); // State to store tracking URLs
//     const [currentStatus, setCurrentStatus] = useState({});
//     const [deliveredDates, setDeliveredDates] = useState({});
//     const [orderDateFilter, setOrderDateFilter] = useState(''); // State for order date filter
    
//     // Pagination states
//     const [currentPage, setCurrentPage] = useState(1); // Tracks current page
//     const [itemsPerPage] = useState(10); // Number of orders per page

//     const skuPriceCache = {};

//     const fetchDesignDetailsBySKU = async (sku, userId) => {
//         if (!sku) return null;

//         if (skuPriceCache[sku]) {
//             return skuPriceCache[sku];
//         }

//         try {
//             const designsCollection = collection(db, 'users', userId, 'designs');
//             const q = query(designsCollection, where('SKUs', 'array-contains', sku));
//             const designSnapshot = await getDocs(q);

//             if (!designSnapshot.empty) {
//                 const designData = designSnapshot.docs[0].data();
//                 skuPriceCache[sku] = designData.totalPrice;
//                 return designData.totalPrice;
//             }
//             return null;
//         } catch (error) {
//             console.error("Error fetching design details by SKU:", error);
//             return null;
//         }
//     };

//     const fetchTrackingData = async (orderId) => {
//         try {
//             const response = await axios.get(`http://localhost:8080/api/ongoing-track-order?order_id=${orderId}`);
//             if (response.data) {
//                 return {
//                     trackingUrl: response.data.trackingUrl,
//                     currentStatus: response.data.currentStatus,
//                     deliveredDate: response.data.deliveredDate,
//                 };
//             } else {
//                 console.error("No tracking data found in response");
//                 return null;
//             }
//         } catch (error) {
//             console.error("Error fetching tracking URL for order", orderId, error);
//             return null;
//         }
//     };

//     const updateOrderStatusInFirebase = async (orderId, userId, deliveredDate) => {
//         try {
//             const orderRef = doc(db, 'users', userId, 'orders', orderId);
//             await updateDoc(orderRef, {
//                 deliveredDate: deliveredDate,
//                 currentStatus: 'Delivered',
//             });
//             console.log('Order status updated to delivered in Firebase');
//         } catch (error) {
//             console.error('Error updating order in Firebase', error);
//         }
//     };

//     const updateRTOStatusInFirebase = async (orderId, userId) => {
//         try {
//             const orderRef = doc(db, 'users', userId, 'orders', orderId);
//             const currentDate = new Date();

//             await updateDoc(orderRef, {
//                 RTOinDate: currentDate,
//                 currentStatus: 'RTO INITIATED',
//             });
//             console.log('Order status updated to RTO INITIATED in Firebase');
//         } catch (error) {
//             console.error('Error updating order in Firebase', error);
//         }
//     };

//     const fetchOrders = async () => {
//         try {
//             const usersSnapshot = await getDocs(collection(db, 'users'));
//             const allOrders = [];
//             const brandsSet = new Set(); // To hold unique brands

//             for (const userDoc of usersSnapshot.docs) {
//                 const userId = userDoc.id;
//                 const userData = userDoc.data();
//                 const brandName = userData.brandName || 'Unknown Brand';

//                 // Add the brand name to the set for uniqueness
//                 brandsSet.add(brandName);

//                 const ordersQuery = query(
//                     collection(db, 'users', userId, 'orders'),
//                     where('status', '==', 'confirmed'),
//                     limit(100) // Limiting the number of orders per query
//                 );
//                 const ordersSnapshot = await getDocs(ordersQuery);

//                 for (const orderDoc of ordersSnapshot.docs) {
//                     const orderData = orderDoc.data();
//                     const orderItems = orderData.order_items || [];

//                     let totalCost = 0;
//                     const orderDate = orderData.order_date || null;

//                     for (const item of orderItems) {
//                         const designPrice = await fetchDesignDetailsBySKU(item.sku, userId);
//                         if (designPrice) {
//                             totalCost += designPrice;
//                         }
//                     }

//                     allOrders.push({
//                         id: orderDoc.id,
//                         brandName,
//                         UniteeTotalPrice: orderData.UniteeTotalPrice,
//                         shippingCost: orderData.shippingCost,
//                         status: orderData.status || 'pending',
//                         userId,
//                         orderDate,
//                     });
//                 }
//             }

//             // Convert the brands set into an array and set the brand options
//             setAllBrands(Array.from(brandsSet));

//             setOrders(allOrders);

//             const newTrackingUrls = {};
//             const newCurrentStatus = {};
//             const newDeliveredDates = {};

//             for (const order of allOrders) {
//                 const trackingData = await fetchTrackingData(order.id);
//                 if (trackingData) {
//                     newTrackingUrls[order.id] = trackingData.trackingUrl;
//                     newCurrentStatus[order.id] = trackingData.currentStatus;
//                     newDeliveredDates[order.id] = trackingData.deliveredDate;

//                     if (trackingData.currentStatus === 'Delivered') {
//                         updateOrderStatusInFirebase(order.id, order.userId, trackingData.deliveredDate);
//                     }

//                     if (trackingData.currentStatus === 'RTO Initiated') {
//                         updateRTOStatusInFirebase(order.id, order.userId);
//                     }
//                 } else {
//                     newTrackingUrls[order.id] = 'N/A';
//                     newCurrentStatus[order.id] = 'N/A';
//                     newDeliveredDates[order.id] = 'N/A';
//                 }
//             }

//             setTrackingUrls(newTrackingUrls);
//             setCurrentStatus(newCurrentStatus);
//             setDeliveredDates(newDeliveredDates);
//             setLoading(false);
//         } catch (error) {
//             console.error('Error fetching orders:', error);
//             toast.error('Failed to load orders');
//             setLoading(false);
//         }
//     };

//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//     };

//     const handleBrandFilterChange = (event) => {
//         setBrandFilter(event.target.value);
//     };

//     const handleDateFilterChange = (event) => {
//         setOrderDateFilter(event.target.value);
//     };

//     // Filter orders based on the search query, selected brand name, and order date filter
//     const filteredOrders = orders
//         .filter(order =>
//             order.id.toLowerCase().includes(searchQuery.toLowerCase()) &&
//             (brandFilter === '' || order.brandName === brandFilter) && // Filter by brand name
//             (orderDateFilter === '' || (order.orderDate && order.orderDate.split('T')[0] === orderDateFilter)) // Filter by order date
//         );

//     // Pagination logic
//     const indexOfLastOrder = currentPage * itemsPerPage;
//     const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
//     const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

//     // Change page
//     const paginate = (pageNumber) => setCurrentPage(pageNumber);

//     // Calculate total number of pages
//     const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

//     useEffect(() => {
//         fetchOrders();
//     }, []);

//     return (
//         <div className="admin-orders-page">
//             <h1>Admin Orders</h1>

//             {/* Search input */}
//             <div className="search-container">
//                 <input
//                     type="text"
//                     placeholder="Search by Order ID"
//                     value={searchQuery}
//                     onChange={handleSearchChange}
//                     className="search-input"
//                 />
//             </div>

//             {/* Brand Name Dropdown */}
//             <div className="filter-container">
//                 <select
//                     value={brandFilter}
//                     onChange={handleBrandFilterChange}
//                     className="filter-select"
//                 >
//                     <option value="">All Brands</option>
//                     {allBrands.map(brand => (
//                         <option key={brand} value={brand}>
//                             {brand}
//                         </option>
//                     ))}
//                 </select>
//             </div>

//             {/* Order Date Filter */}
//             <div className="filter-container">
//                 <input
//                     type="date"
//                     value={orderDateFilter}
//                     onChange={handleDateFilterChange}
//                     className="filter-select"
//                 />
//             </div>

//             {loading ? (
//                 <p>Loading orders...</p>
//             ) : (
//                 <>
//                     <table className="admin-orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Brand Name</th>
//                                 <th>Order No</th>
//                                 <th>Total Cost</th>
//                                 <th>Status</th>
//                                 <th>Tracking URL</th>
//                                 <th>Current Status</th>
//                                 <th>Delivered Date</th>
//                                 <th>Order Date</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {currentOrders.map(order => (
//                                 <tr key={order.id}>
//                                     <td>{order.brandName}</td>
//                                     <td>{order.id}</td>
//                                     <td>₹{order.UniteeTotalPrice}</td>
//                                     <td>{order.status}</td>
//                                     <td>
//                                         {trackingUrls[order.id] && trackingUrls[order.id] !== 'N/A' ? (
//                                             <a href={trackingUrls[order.id]} target="_blank" rel="noopener noreferrer">
//                                                 Track Order
//                                             </a>
//                                         ) : (
//                                             <span>Not Available</span>
//                                         )}
//                                     </td>
//                                     <td>{currentStatus[order.id] || 'N/A'}</td>
//                                     <td>{deliveredDates[order.id] || 'N/A'}</td>
//                                     <td>{order.orderDate ? order.orderDate.split('T')[0] : 'N/A'}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>

//                     {/* Pagination Controls */}
//                     <div className="pagination">
//                         <button
//                             onClick={() => paginate(currentPage - 1)}
//                             disabled={currentPage === 1}
//                         >
//                             Previous
//                         </button>
//                         {[...Array(totalPages).keys()].map(number => (
//                             <button
//                                 key={number + 1}
//                                 onClick={() => paginate(number + 1)}
//                                 className={currentPage === number + 1 ? 'active' : ''}
//                             >
//                                 {number + 1}
//                             </button>
//                         ))}
//                         <button
//                             onClick={() => paginate(currentPage + 1)}
//                             disabled={currentPage === totalPages}
//                         >
//                             Next
//                         </button>
//                     </div>
//                 </>
//             )}
//             <ToastContainer />
//         </div>
//     );
// };

// export default OngoingOrders;


// import React, { useEffect, useState } from 'react';
// import Modal from 'react-modal';
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import { db } from '../firebase'; // Adjust the import to match your Firebase configuration
// import '../style/Adminorders.css';

// const OngoingOrders = () => {
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   // Fetching orders from Firebase
//   const fetchOrders = async () => {
//     try {
//       const usersSnapshot = await getDocs(collection(db, 'users'));
//       const allOrders = [];

//       for (const userDoc of usersSnapshot.docs) {
//         const userId = userDoc.id;
//         const ordersQuery = query(
//           collection(db, 'users', userId, 'orders'),
//           where('status', '==', 'confirmed')
//         );
//         const ordersSnapshot = await getDocs(ordersQuery);

//         for (const orderDoc of ordersSnapshot.docs) {
//           const orderData = orderDoc.data();
//           allOrders.push({
//             id: orderDoc.id,
//             userId,
//             ...orderData, // Add all order data
//           });
//         }
//       }

//       setOrders(allOrders);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error fetching orders:', error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchOrders();
//   }, []);

//   // Handle searching
//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   // Filter orders based on search query
//   const filteredOrders = orders.filter(order =>
//     order.id.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   // Open modal with selected order details
//   const openModal = (order) => {
//     setSelectedOrder(order);
//     setIsModalOpen(true);
//   };

//   // Close the modal
//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedOrder(null);
//   };

//   return (
//     <div className="admin-orders-page">
//       <h1>Admin Orders</h1>

//       {/* Search input */}
//       <div className="search-container">
//         <input
//           type="text"
//           placeholder="Search by Order ID"
//           value={searchQuery}
//           onChange={handleSearchChange}
//           className="search-input"
//         />
//       </div>

//       {loading ? (
//         <p>Loading orders...</p>
//       ) : (
//         <>
//           <table className="admin-orders-table">
//             <thead>
//               <tr>
//                 <th>Order No</th>
//                 <th>Brand name</th>
//                 <th>Status</th>
//                 <th>Total Price</th>
//                 <th>Order Date</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredOrders.map(order => (
//                 <tr key={order.id} onClick={() => openModal(order)}>
//                   <td>{order.id}</td>
//                   <td>{order.billing_customer_name}</td>
//                   <td>{order.status}</td>
//                   <td>₹{order.UniteeTotalPrice}</td>
//                   <td>{new Date(order.order_date).toLocaleDateString()}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </>
//       )}

//       {/* Modal for showing order details */}
//       <Modal
//         isOpen={isModalOpen}
//         onRequestClose={closeModal}
//         contentLabel="Order Details"
//         ariaHideApp={false}
//         className="modal"
//         overlayClassName="overlay"
//       >
//         <h2>Order Details</h2>
//         {selectedOrder && (
//           <div className="order-details">
//             <p><strong>Customer Name:</strong> {selectedOrder.billing_customer_name}</p>
//             <p><strong>Email:</strong> {selectedOrder.billing_email}</p>
//             <p><strong>Phone:</strong> {selectedOrder.billing_phone}</p>
//             <p><strong>Address:</strong> {selectedOrder.billing_address}</p>
//             <p><strong>City:</strong> {selectedOrder.billing_city}</p>
//             <p><strong>State:</strong> {selectedOrder.billing_state}</p>
//             <p><strong>Country:</strong> {selectedOrder.billing_country}</p>
//             <p><strong>Pincode:</strong> {selectedOrder.billing_pincode}</p>

//             <h4>Order Items:</h4>
//             {/* Ensure order_items is an array before mapping */}
//             {Array.isArray(selectedOrder.order_items) && selectedOrder.order_items.length > 0 ? (
//               selectedOrder.order_items.map((item, index) => (
//                 <div key={index}>
//                   <p><strong>Design Name:</strong> {item.name}</p>
//                   <p><strong>SKU:</strong> {item.sku}</p>
//                   <p><strong>Price:</strong> ₹{item.selling_price}</p>
//                   <p><strong>Size:</strong> {item.size}</p>
//                   <p><strong>Units:</strong> {item.units}</p>
//                 </div>
//               ))
//             ) : (
//               <p>No items available for this order.</p>
//             )}

//             <h4>Order Information:</h4>
//             <p><strong>Order ID:</strong> {selectedOrder.order_id}</p>
//             <p><strong>Order Date:</strong> {new Date(selectedOrder.order_date).toLocaleString()}</p>
//             <p><strong>Payment Method:</strong> {selectedOrder.payment_method}</p>
//             <p><strong>Total Price:</strong> ₹{selectedOrder.UniteeTotalPrice}</p>
//             <p><strong>Invoice:</strong> <a href={selectedOrder.invoiceUrl} target="_blank" rel="noopener noreferrer">View Invoice</a></p>
//           </div>
//         )}
//         <button onClick={closeModal}>Close</button>
//       </Modal>
//     </div>
//   );
// };

// export default OngoingOrders;



import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where, updateDoc, doc, limit } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import to match your Firebase configuration
import '../style/Adminorders.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'react-modal';

const OngoingOrders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [brandFilter, setBrandFilter] = useState(''); // State for brand filter (Dropdown value)
    const [allBrands, setAllBrands] = useState([]); // State to hold all unique brand names
    const [trackingUrls, setTrackingUrls] = useState({}); // State to store tracking URLs
    const [currentStatus, setCurrentStatus] = useState({});
    const [deliveredDates, setDeliveredDates] = useState({});
    const [orderDateFilter, setOrderDateFilter] = useState(''); // State for order date filter
    
    // Pagination states
    const [currentPage, setCurrentPage] = useState(1); // Tracks current page
    const [itemsPerPage] = useState(10); // Number of orders per page

    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
    const [selectedOrder, setSelectedOrder] = useState(null); // Selected order state

    const skuPriceCache = {};

    const fetchDesignDetailsBySKU = async (sku, userId) => {
        if (!sku) return null;

        if (skuPriceCache[sku]) {
            return skuPriceCache[sku];
        }

        try {
            const designsCollection = collection(db, 'users', userId, 'designs');
            const q = query(designsCollection, where('SKUs', 'array-contains', sku));
            const designSnapshot = await getDocs(q);

            if (!designSnapshot.empty) {
                const designData = designSnapshot.docs[0].data();
                skuPriceCache[sku] = designData.totalPrice;
                return designData.totalPrice;
            }
            return null;
        } catch (error) {
            console.error("Error fetching design details by SKU:", error);
            return null;
        }
    };

    const fetchTrackingData = async (orderId) => {
        try {
            const response = await axios.get(`https://ufb-1.onrender.com/api/ongoing-track-order?order_id=${orderId}`);
            if (response.data) {
                return {
                    trackingUrl: response.data.trackingUrl,
                    currentStatus: response.data.currentStatus,
                    deliveredDate: response.data.deliveredDate,
                };
            } else {
                console.error("No tracking data found in response");
                return null;
            }
        } catch (error) {
            console.error("Error fetching tracking URL for order", orderId, error);
            return null;
        }
    };

    const updateOrderStatusInFirebase = async (orderId, userId, deliveredDate) => {
        try {
            const orderRef = doc(db, 'users', userId, 'orders', orderId);
            await updateDoc(orderRef, {
                deliveredDate: deliveredDate,
                currentStatus: 'Delivered',
            });
            console.log('Order status updated to delivered in Firebase');
        } catch (error) {
            console.error('Error updating order in Firebase', error);
        }
    };

    const updateRTOStatusInFirebase = async (orderId, userId) => {
        try {
            const orderRef = doc(db, 'users', userId, 'orders', orderId);
            const currentDate = new Date();

            await updateDoc(orderRef, {
                RTOinDate: currentDate,
                currentStatus: 'RTO INITIATED',
            });
            console.log('Order status updated to RTO INITIATED in Firebase');
        } catch (error) {
            console.error('Error updating order in Firebase', error);
        }
    };

    const fetchOrders = async () => {
        try {
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const allOrders = [];
            const brandsSet = new Set(); // To hold unique brands

            for (const userDoc of usersSnapshot.docs) {
                const userId = userDoc.id;
                const userData = userDoc.data();
                const brandName = userData.brandName || 'Unknown Brand';

                // Add the brand name to the set for uniqueness
                brandsSet.add(brandName);

                const ordersQuery = query(
                    collection(db, 'users', userId, 'orders'),
                    where('status', '==', 'confirmed'),
                    limit(100) // Limiting the number of orders per query
                );
                const ordersSnapshot = await getDocs(ordersQuery);

                for (const orderDoc of ordersSnapshot.docs) {
                    const orderData = orderDoc.data();
                    const orderItems = orderData.order_items || [];

                    let totalCost = 0;
                    const orderDate = orderData.order_date || null;

                    for (const item of orderItems) {
                        const designPrice = await fetchDesignDetailsBySKU(item.sku, userId);
                        if (designPrice) {
                            totalCost += designPrice;
                        }
                    }

                    allOrders.push({
                        id: orderDoc.id,
                        brandName,
                        UniteeTotalPrice: orderData.UniteeTotalPrice,
                        shippingCost: orderData.shippingCost,
                        status: orderData.status || 'pending',
                        userId,
                        orderDate,
                        billingCustomerName: orderData.billing_customer_name,
                        billingEmail: orderData.billing_email,
                        billingPhone: orderData.billing_phone,
                        billingAddress: orderData.billing_address,
                        payment_method: orderData.payment_method,
                        billingCity: orderData.billing_city,
                        billingState: orderData.billing_state,
                        billingCountry: orderData.billing_country,
                        billingPincode: orderData.billing_pincode,
                        orderItems: orderData.order_items || [],
                    });
                }
            }

            // Convert the brands set into an array and set the brand options
            setAllBrands(Array.from(brandsSet));

            setOrders(allOrders);

            const newTrackingUrls = {};
            const newCurrentStatus = {};
            const newDeliveredDates = {};

            for (const order of allOrders) {
                const trackingData = await fetchTrackingData(order.id);
                if (trackingData) {
                    newTrackingUrls[order.id] = trackingData.trackingUrl;
                    newCurrentStatus[order.id] = trackingData.currentStatus;
                    newDeliveredDates[order.id] = trackingData.deliveredDate;

                    if (trackingData.currentStatus === 'Delivered') {
                        updateOrderStatusInFirebase(order.id, order.userId, trackingData.deliveredDate);
                    }

                    if (trackingData.currentStatus === 'RTO Initiated') {
                        updateRTOStatusInFirebase(order.id, order.userId);
                    }
                } else {
                    newTrackingUrls[order.id] = 'N/A';
                    newCurrentStatus[order.id] = 'N/A';
                    newDeliveredDates[order.id] = 'N/A';
                }
            }

            setTrackingUrls(newTrackingUrls);
            setCurrentStatus(newCurrentStatus);
            setDeliveredDates(newDeliveredDates);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            toast.error('Failed to load orders');
            setLoading(false);
        }
    };

    const openModal = (order) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOrder(null);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleBrandFilterChange = (event) => {
        setBrandFilter(event.target.value);
    };

    const handleDateFilterChange = (event) => {
        setOrderDateFilter(event.target.value);
    };

    // Filter orders based on the search query, selected brand name, and order date filter
    const filteredOrders = orders
        .filter(order =>
            order.id.toLowerCase().includes(searchQuery.toLowerCase()) &&
            (brandFilter === '' || order.brandName === brandFilter) && // Filter by brand name
            (orderDateFilter === '' || (order.orderDate && order.orderDate.split('T')[0] === orderDateFilter)) // Filter by order date
        );

    // Pagination logic
    const indexOfLastOrder = currentPage * itemsPerPage;
    const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total number of pages
    const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

    useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <div className="admin-orders-page">
            <h1>Admin Orders</h1>

            {/* Search input */}
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by Order ID"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
            </div>

            {/* Brand Name Dropdown */}
            <div className="filter-container">
                <select
                    value={brandFilter}
                    onChange={handleBrandFilterChange}
                    className="filter-select"
                >
                    <option value="">All Brands</option>
                    {allBrands.map(brand => (
                        <option key={brand} value={brand}>
                            {brand}
                        </option>
                    ))}
                </select>
            </div>

            {/* Order Date Filter */}
            <div className="filter-container">
                <input
                    type="date"
                    value={orderDateFilter}
                    onChange={handleDateFilterChange}
                    className="filter-select"
                />
            </div>

            {loading ? (
                <p>Loading orders...</p>
            ) : (
                <>
                    <table className="admin-orders-table">
                        <thead>
                            <tr>
                                <th>Brand Name</th>
                                <th>Order No</th>
                                <th>Total Cost</th>
                                <th>Status</th>
                                <th>Tracking URL</th>
                                <th>Current Status</th>
                                <th>Delivered Date</th>
                                <th>Order Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentOrders.map(order => (
                                <tr key={order.id} onClick={() => openModal(order)}>
                                    <td>{order.brandName}</td>
                                    <td>{order.id}</td>
                                    <td>₹{order.UniteeTotalPrice}</td>
                                    <td>{order.status}</td>
                                    <td>
                                        {trackingUrls[order.id] && trackingUrls[order.id] !== 'N/A' ? (
                                            <a href={trackingUrls[order.id]} target="_blank" rel="noopener noreferrer">
                                                Track Order
                                            </a>
                                        ) : (
                                            <span>Not Available</span>
                                        )}
                                    </td>
                                    <td>{currentStatus[order.id] || 'N/A'}</td>
                                    <td>{deliveredDates[order.id] || 'N/A'}</td>
                                    <td>{order.orderDate ? order.orderDate.split('T')[0] : 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <div className="pagination">
                        <button
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        {[...Array(totalPages).keys()].map(number => (
                            <button
                                key={number + 1}
                                onClick={() => paginate(number + 1)}
                                className={currentPage === number + 1 ? 'active' : ''}
                            >
                                {number + 1}
                            </button>
                        ))}
                        <button
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </>
            )}

            {/* Modal for showing order details */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Order Details"
                ariaHideApp={false}
                className="modal"
                overlayClassName="overlay"
            >
                <h2>Order Details</h2>
                {selectedOrder && (
                    <div className="order-details">
                        <p><strong>Customer Name:</strong> {selectedOrder.billingCustomerName}</p>
                        <p><strong>Email:</strong> {selectedOrder.billingEmail}</p>
                        <p><strong>Phone:</strong> {selectedOrder.billingPhone}</p>
                        <p><strong>Address:</strong> {selectedOrder.billingAddress}</p>
                        <p><strong>City:</strong> {selectedOrder.billingCity}</p>
                        <p><strong>State:</strong> {selectedOrder.billingState}</p>
                        <p><strong>Country:</strong> {selectedOrder.billingCountry}</p>
                        <p><strong>Pincode:</strong> {selectedOrder.billingPincode}</p>

                        <h4>Order Items:</h4>
                        {Array.isArray(selectedOrder.orderItems) && selectedOrder.orderItems.length > 0 ? (
                            selectedOrder.orderItems.map((item, index) => (
                                <div key={index}>
                                    <p><strong>Product Name:</strong> {item.name}</p>
                                    <p><strong>Design Name:</strong> {item.designName || 'NA'}</p>
                                    <p><strong>SKU:</strong> {item.sku}</p>
                                    <p><strong>Price:</strong> ₹{item.selling_price}</p>
                                    <p><strong>Size:</strong> {item.size}</p>
                                    <p><strong>Units:</strong> {item.units}</p>
                                </div>
                            ))
                        ) : (
                            <p>No items available for this order.</p>
                        )}

                        <h4>Order Information:</h4>
                        <p><strong>Order ID:</strong> {selectedOrder.id}</p>
                        <p><strong>Order Date:</strong> {new Date(selectedOrder.orderDate).toLocaleString()}</p>
                        <p><strong>Payment Method:</strong> {selectedOrder.payment_method}</p>
                        <p><strong>Total Price:</strong> ₹{selectedOrder.UniteeTotalPrice}</p>
                        <p><strong>Invoice:</strong> <a href={selectedOrder.invoiceUrl} target="_blank" rel="noopener noreferrer">View Invoice</a></p>
                    </div>
                )}
                <button onClick={closeModal}>Close</button>
            </Modal>

            <ToastContainer />
        </div>
    );
};

export default OngoingOrders;
