// import React, { useEffect, useState } from 'react';
// import { db } from '../firebase';
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import '../style/Adminorders.css'

// const SalesInvoice = () => {
//     const [invoices, setInvoices] = useState([]);
//     const [loading, setLoading] = useState(true);

//     const fetchInvoices = async () => {
//         setLoading(true);
        
//         try {
//             const usersSnapshot = await getDocs(collection(db, 'users'));
//             const allInvoices = [];

//             for (const userDoc of usersSnapshot.docs) {
//                 const userData = userDoc.data();
//                 const brandName = userData.brandName || 'Unknown Brand';
//                 const userId = userDoc.id;

//                 // Query for orders with invoices in this shop
//                 const ordersCollection = collection(db, 'users', userId, 'orders');
//                 const ordersQuery = query(ordersCollection, where('invoiceUrl', '!=', null));
//                 const ordersSnapshot = await getDocs(ordersQuery);

//                 ordersSnapshot.forEach(orderDoc => {
//                     const orderData = orderDoc.data();
//                     allInvoices.push({
//                         id: orderDoc.id,
//                         brandName: brandName,
//                         invoiceUrl: orderData.invoiceUrl,
//                     });
//                 });
//             }

//             setInvoices(allInvoices);
//         } catch (error) {
//             console.error("Error fetching invoices:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchInvoices();
//     }, []);

//     const handleDownloadInvoice = (invoiceUrl) => {
//         if (invoiceUrl) {
//             window.open(invoiceUrl, '_blank');
//         } else {
//             alert('Invoice not available for this order.');
//         }
//     };

//     if (loading) {
//         return <div>Loading invoices...</div>;
//     }

//     return (
//         <div className="admin-orders-page">
//             {/* <Header /> */}
//             <div className="orders-container">
            
//                 <div className="main-content">
//                     <h1>Sales Invoices</h1>
//                     <table className="admin-orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Brand Name</th>
//                                 <th>Order Number</th>
//                                 <th>Invoice</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {invoices.map((invoice) => (
//                                 <tr key={invoice.id}>
//                                     <td>{invoice.brandName}</td>
//                                     <td>{invoice.id}</td>
//                                     <td>
//                                         {invoice.invoiceUrl ? (
//                                             <button 
//                                                 onClick={() => handleDownloadInvoice(invoice.invoiceUrl)} 
//                                                 className="download-invoice-btn"
//                                             >
//                                                 Download Invoice
//                                             </button>
//                                         ) : (
//                                             <span>No Invoice</span>
//                                         )}
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SalesInvoice;


import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import '../style/Adminorders.css';

// Helper function to extract the file name from the invoice URL and decode it
const extractInvoiceName = (url) => {
    const urlParts = url.split('invoices%2F'); // Split by 'invoices%2F' to isolate the file name part
    const fileNameWithExtension = urlParts[1]?.split('?')[0]; // Get the part before the query parameters (after '?')
    return decodeURIComponent(fileNameWithExtension); // Decode URL to get readable file name
};

const SalesInvoice = () => {
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchInvoices = async () => {
        setLoading(true);

        try {
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const allInvoices = [];

            for (const userDoc of usersSnapshot.docs) {
                const userData = userDoc.data();
                const brandName = userData.brandName || 'Unknown Brand';
                const userId = userDoc.id;

                // Query for orders with invoices in this shop
                const ordersCollection = collection(db, 'users', userId, 'orders');
                const ordersQuery = query(ordersCollection, where('invoiceUrl', '!=', null));
                const ordersSnapshot = await getDocs(ordersQuery);

                ordersSnapshot.forEach(orderDoc => {
                    const orderData = orderDoc.data();
                    const orderDate = orderData.order_date ? orderData.order_date.split('T')[0] : 'N/A';
                    const invoiceUrl = orderData.invoiceUrl;
                    const invoiceName = extractInvoiceName(invoiceUrl); // Extract the invoice name from the URL

                    allInvoices.push({
                        id: orderDoc.id,
                        brandName: brandName,
                        invoiceUrl: invoiceUrl,
                        invoiceName: invoiceName,
                        orderDate: orderDate,
                    });
                });
            }

            setInvoices(allInvoices);
        } catch (error) {
            console.error("Error fetching invoices:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInvoices();
    }, []);

    const handleDownloadInvoice = (invoiceUrl) => {
        if (invoiceUrl) {
            window.open(invoiceUrl, '_blank');
        } else {
            alert('Invoice not available for this order.');
        }
    };

    if (loading) {
        return <div>Loading invoices...</div>;
    }

    return (
        <div className="admin-orders-page">
            <div className="orders-container">
                <div className="main-content">
                    <h1>Sales Invoices</h1>
                    <table className="admin-orders-table">
                        <thead>
                            <tr>
                                <th>Brand Name</th>
                                <th>Order Number</th>
                                <th>Invoice Name</th>
                                <th>Order Date</th>
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.map((invoice) => (
                                <tr key={invoice.id}>
                                    <td>{invoice.brandName}</td>
                                    <td>{invoice.id}</td>
                                    <td>{invoice.invoiceName}</td>
                                    <td>{invoice.orderDate}</td>
                                    <td>
                                        {invoice.invoiceUrl ? (
                                            <button
                                                onClick={() => handleDownloadInvoice(invoice.invoiceUrl)}
                                                className="download-invoice-btn"
                                            >
                                                Download Invoice
                                            </button>
                                        ) : (
                                            <span>No Invoice</span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SalesInvoice;
