import React from 'react';
import { Link } from 'react-router-dom';  // Import Link for routing
import '../style/AdminPanel.css';

const AdminPanel = () => {
  return (
    <div className="admin-panel">
      <h2>ADMIN PANEL</h2>
      <div className="button-grid">
        <Link to="/trackgrowth">
          <button className="panel-button">Track Growth</button>
        </Link>
        <Link to="/ongoing-orders">
          <button className="panel-button">Ongoing Orders</button>
        </Link>
        <Link to="/sales-invoices">
          <button className="panel-button">Sales Invoices</button>
        </Link>
        <Link to="/Transaction">
          <button className="panel-button">Transactions</button>
        </Link>
        <Link to="/Customer-support">
          <button className="panel-button">Customer Support</button>
        </Link>
        <Link to="/inventory">
          <button className="panel-button">Inventory Management</button>
        </Link>
        <Link to="/cod-remittance">
          <button className="panel-button">COD Remittance</button>
        </Link>

        <Link to="/vendor-payments">
          <button className="panel-button">Vendor Payments</button>
        </Link>
       
        <Link to="/designs">
          <button className="panel-button">Designs</button>
        </Link>
      </div>
    </div>
  );
};

export default AdminPanel;

