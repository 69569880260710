import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import '../style/AdminPanel.css';

const Trackgrowth = () => {
  return (
    <div className="admin-panel">
      <h2>Track Growth</h2>
      <div className="button-grid">
        {/* Wrapping each button in a Link to add navigation */}
        <Link to="/trackgrowth/average-order-value">
          <button className="panel-button">Average Order Value</button>
        </Link>
        <Link to="/trackgrowth/best-sellers">
          <button className="panel-button">Best Sellers</button>
        </Link>
        <Link to="/trackgrowth/brand-activity">
          <button className="panel-button">Brand Activity</button>
        </Link>
        <Link to="/trackgrowth/revenue-growth">
          <button className="panel-button">Revenue Growth</button>
        </Link>
        <Link to="/trackgrowth/brand-split">
          <button className="panel-button">Order wise brand split</button>
        </Link>
        <Link to="/trackgrowth/unresolved-problem">
          <button className="panel-button">Un resolved Problem</button>
        </Link>
      </div>
    </div>
  );
};

export default Trackgrowth;
