// import React, { useEffect, useState } from 'react';
// import { collection, getDocs } from 'firebase/firestore';
// import { db } from '../firebase'; // Adjust the import to match your Firebase configuration
// import '../style/Bestsellers.css';
// import { ToastContainer, toast } from 'react-toastify';

// const Bestsellers = () => {
//   const [bestsellers, setBestsellers] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // Fetch bestsellers data
//     const fetchBestsellers = async () => {
//       try {
//         const shopsSnapshot = await getDocs(collection(db, 'shops'));
//         const allBestsellers = [];

//         // Iterate through each shop to fetch orders and associated design details
//         for (const shopDoc of shopsSnapshot.docs) {
//           const shopId = shopDoc.id;

//           // Fetch orders for each shop
//           const ordersSnapshot = await getDocs(collection(db, 'shops', shopId, 'orders'));
          
//           // Iterate through each order and its items
//           for (const orderDoc of ordersSnapshot.docs) {
//             const orderData = orderDoc.data();
//             const orderItems = orderData.order_items || [];

//             // Process each order item
//             for (const item of orderItems) {
//               const sku = item.sku;

//               // Fetch design details by SKU from the designs collection
//               const designsSnapshot = await getDocs(collection(db, 'shops', shopId, 'designs'));
//               const designDoc = designsSnapshot.docs.find(doc => 
//                 doc.data().SKUs.includes(sku)
//               );

//               if (designDoc) {
//                 const designData = designDoc.data();
//                 const productName = designData.productName || 'N/A';
//                 const productGSM = designData.productGSM || 'N/A';

//                 // Add product details and quantity to the allBestsellers array
//                 allBestsellers.push({
//                   orderId: orderDoc.id,
//                   productName,
//                   productGSM,
//                   quantity: item.quantity,
//                   totalPrice: orderData.totalCost,
//                   status: orderData.status || 'pending',
//                 });
//               }
//             }
//           }
//         }

//         setBestsellers(allBestsellers);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching bestsellers:', error);
//         toast.error('Failed to load bestsellers');
//         setLoading(false);
//       }
//     };

//     fetchBestsellers();
//   }, []);

//   if (loading) {
//     return <p>Loading bestsellers...</p>;
//   }

//   return (
//     <div className="bestsellers-page">
//       <h1>Bestsellers</h1>
//       <table className="bestsellers-table">
//         <thead>
//           <tr>
//             <th>Order ID</th>
//             <th>Product Name</th>
//             <th>Product GSM</th>
//             <th>Quantity</th>
//             <th>Total Price</th>
//             <th>Status</th>
//           </tr>
//         </thead>
//         <tbody>
//           {bestsellers.map((item, index) => (
//             <tr key={index}>
//               <td>{item.orderId}</td>
//               <td>{item.productName}</td>
//               <td>{item.productGSM}</td>
//               <td>{item.quantity}</td>
//               <td>₹{item.totalPrice}</td>
//               <td>{item.status}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <ToastContainer />
//     </div>
//   );
// };

// export default Bestsellers;

import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import to match your Firebase configuration
import '../style/Bestsellers.css';
import { ToastContainer, toast } from 'react-toastify';

const Bestsellers = () => {
  const [bestsellers, setBestsellers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch bestsellers data
    const fetchBestsellers = async () => {
      try {
        const shopsSnapshot = await getDocs(collection(db, 'shops'));
        const productCountMap = new Map();

        // Iterate through each shop to fetch orders and associated design details
        for (const shopDoc of shopsSnapshot.docs) {
          const shopId = shopDoc.id;

          // Fetch orders for each shop
          const ordersSnapshot = await getDocs(collection(db, 'shops', shopId, 'orders'));
          
          // Iterate through each order and its items
          for (const orderDoc of ordersSnapshot.docs) {
            const orderData = orderDoc.data();
            const orderItems = orderData.order_items || [];

            // Process each order item
            for (const item of orderItems) {
              const sku = item.sku;

              // Fetch design details by SKU from the designs collection
              const designsSnapshot = await getDocs(collection(db, 'shops', shopId, 'designs'));
              const designDoc = designsSnapshot.docs.find(doc => 
                doc.data().SKUs.includes(sku)
              );

              if (designDoc) {
                const designData = designDoc.data();
                const productName = designData.productName || 'N/A';
                const productGSM = designData.productGSM || 'N/A';

                // Create a unique key for the combination of productName and productGSM
                const productKey = `${productName} - ${productGSM}`;

                // Increment the count for this product
                if (productCountMap.has(productKey)) {
                  productCountMap.set(productKey, productCountMap.get(productKey) + 1);
                } else {
                  productCountMap.set(productKey, 1);
                }
              }
            }
          }
        }

        // Convert the Map to an array for rendering
        const bestsellersList = Array.from(productCountMap, ([productKey, count]) => ({
          product: productKey,
          count,
        }));

        setBestsellers(bestsellersList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching bestsellers:', error);
        toast.error('Failed to load bestsellers');
        setLoading(false);
      }
    };

    fetchBestsellers();
  }, []);

  if (loading) {
    return <p>Loading bestsellers...</p>;
  }

  return (
    <div className="bestsellers-page">
      <h1>Bestsellers</h1>
      <table className="bestsellers-table">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Product GSM</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {bestsellers.map((item, index) => (
            <tr key={index}>
              <td>{item.product.split(' - ')[0]}</td> {/* Extract Product Name */}
              <td>{item.product.split(' - ')[1]}</td> {/* Extract Product GSM */}
              <td>{item.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
};

export default Bestsellers;
