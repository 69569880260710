import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import '../style/Inventory.css';

const Inventory = () => {
  const [currentView, setCurrentView] = useState('list'); // Tracks the current view: 'list', 'detail', or 'add'
  const [products, setProducts] = useState([]);
  const [productDetail, setProductDetail] = useState(null);
  const [newProduct, setNewProduct] = useState('');
  const [newQuantities, setNewQuantities] = useState({ XS: 0, S: 0, M: 0, L: 0, XL: 0, XXL: 0 });

  const inventoryRef = collection(db, 'inventory');

  // Fetch the list of products
  useEffect(() => {
    const getProducts = async () => {
      const snapshot = await getDocs(inventoryRef);
      const productsData = [];
      snapshot.forEach((doc) => {
        productsData.push({ id: doc.id, ...doc.data() });
      });
      setProducts(productsData);
    };

    getProducts();
  }, []);

  // Fetch product details when the user views a specific product
  const handleViewProduct = async (productId) => {
    const docRef = doc(db, 'inventory', productId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setProductDetail({ id: productId, ...docSnap.data() });
      setCurrentView('detail');
    } else {
      console.error('Product not found');
    }
  };

  // Handle adding a new product
  const handleAddProduct = async () => {
    if (newProduct) {
      // Ensure all size quantities are valid before adding the new product
      const validQuantities = ['XS', 'S', 'M', 'L', 'XL', 'XXL'].reduce((acc, size) => {
        const quantity = newQuantities[size];
        if (quantity >= 0) {
          acc[size] = quantity;
        } else {
          console.error(`Invalid quantity for size ${size}`);
        }
        return acc;
      }, {});

      if (Object.keys(validQuantities).length === 0) {
        console.error('No valid quantities provided for the new product');
        return;
      }

      await setDoc(doc(db, 'inventory', newProduct), validQuantities);
      setCurrentView('list'); // Return to the product list after adding
      setNewProduct('');
      setNewQuantities({XS: 0, S: 0, M: 0, L: 0, XL: 0, XXL: 0 });
    }
  };

  // Handle updating quantities in Firestore
  const handleSubmit = async () => {
    if (!productDetail || !productDetail.id) {
      console.error('Product detail is not available or product ID is missing');
      return;
    }

    try {
      const updatedQuantities = ['XS', 'S', 'M', 'L', 'XL', 'XXL'].reduce((acc, size) => {
        if (productDetail[size] !== undefined) {
          acc[size] = productDetail[size];
        }
        return acc;
      }, {});

      if (Object.keys(updatedQuantities).length === 0) {
        console.error('No valid quantities found for the product');
        return;
      }

      const docRef = doc(db, 'inventory', productDetail.id);
      await updateDoc(docRef, updatedQuantities);

      console.log('Product quantities updated in Firestore!');
      setCurrentView('list'); // Return to the product list after successful update
    } catch (error) {
      console.error('Error updating Firestore document:', error);
    }
  };

  // Handle quantity change in product detail
  const handleQuantityChange = (size, newQuantity) => {
    if (!productDetail) {
      console.error('Product detail is not available');
      return;
    }

    // Ensure the newQuantity is a valid number
    if (isNaN(newQuantity)) {
      console.error('Invalid quantity entered');
      return;
    }

    // Prevent negative quantities (optional, based on your business rules)
    if (newQuantity < 0) {
      console.error('Quantity cannot be negative');
      return;
    }

    // Update the local state immutably
    const updatedProductDetail = {
      ...productDetail,
      [size]: newQuantity, // Update the specific size
    };

    setProductDetail(updatedProductDetail); // Update the state with new quantity

    // Optionally, update Firestore in the background
    try {
      const docRef = doc(db, 'inventory', productDetail.id);
      updateDoc(docRef, { [size]: newQuantity }); // Update Firestore with the new quantity
    } catch (error) {
      console.error('Error updating Firestore document:', error);
    }
  };

  // Render product list
  const renderProductList = () => (
    <div className="inventory-list">
      <h1>Product List</h1>
      <button onClick={() => setCurrentView('add')} className="add-product-button">
        Add Product
      </button>
      <table>
        <thead>
          <tr>
            <th>Product</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td>{product.id}</td>
              <td>
                <button onClick={() => handleViewProduct(product.id)}>View</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  // Render product details
  const renderProductDetail = () => {
    if (!productDetail) {
      return <div>Loading product details...</div>;
    }

    return (
      <div className="product-detail">
        <h1>Product: {productDetail.id}</h1>
        <div className="sizes-section">
          {['XS', 'S', 'M', 'L', 'XL', 'XXL'].map((size) => (
            <div key={size} className="size-input-group">
              <label className="size-label">{size.toUpperCase()}:</label>
              <input
                type="number"
                className="size-input"
                value={productDetail[size] || 0} // Default to 0 if the value is undefined
                onChange={(e) => handleQuantityChange(size, parseInt(e.target.value, 10))}
              />
            </div>
          ))}
        </div>
        <button onClick={handleSubmit} className="submit-button">
          Submit
        </button>
        <button onClick={() => setCurrentView('list')}>Back to List</button>
      </div>
    );
  };

  // Render the Add Product form
  const renderAddProduct = () => (
    <div className="add-product-page">
      <h1>Add New Product</h1>
      <input
        type="text"
        placeholder="Product Name"
        value={newProduct}
        onChange={(e) => setNewProduct(e.target.value)}
      />
      <div className="sizes-section">
        {['XS', 'S', 'M', 'L', 'XL', 'XXL'].map((size) => (
          <div key={size} className="size-input-group">
            <label>{size.toUpperCase()}:</label>
            <input
              type="number"
              value={newQuantities[size]}
              onChange={(e) => setNewQuantities({ ...newQuantities, [size]: parseInt(e.target.value, 10) })}
            />
          </div>
        ))}
      </div>
      <button onClick={handleAddProduct}>Add Product</button>
      <button onClick={() => setCurrentView('list')}>Cancel</button>
    </div>
  );

  // Main Render
  return (
    <div className="inventory">
      {currentView === 'list' && renderProductList()}
      {currentView === 'detail' && renderProductDetail()}
      {currentView === 'add' && renderAddProduct()}
    </div>
  );
};

export default Inventory;


// import React, { useState, useEffect } from 'react';
// import { db } from '../firebase';
// import { collection, getDocs, doc, setDoc, updateDoc, getDoc, onSnapshot } from 'firebase/firestore';
// import '../style/Inventory.css';

// const Inventory = () => {
//   const [quantities, setQuantities] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [newProduct, setNewProduct] = useState("");
//   const [newQuantities, setNewQuantities] = useState({ xs: 0, s: 0, m: 0, l: 0, xl: 0, xxl: 0 });

//   const InventoryRef = collection(db, 'inventory');

//   useEffect(() => {
//     const getQuantities = async () => {
//       const snapshot = await getDocs(InventoryRef);
//       const initialQuantities = {};
//       snapshot.forEach(doc => {
//         initialQuantities[doc.id] = doc.data();
//       });
//       setQuantities(initialQuantities);
//       setLoading(false);
//     };

//     getQuantities();

//     const unsubscribe = onSnapshot(InventoryRef, snapshot => {
//       const updatedQuantities = {};
//       snapshot.forEach(doc => {
//         updatedQuantities[doc.id] = doc.data();
//       });
//       console.log('Updated quantities:', updatedQuantities);
//       setQuantities(updatedQuantities);
//     });

//     return () => unsubscribe();
//   }, []);

//   const handleQuantityChange = (product, size, newQuantity) => {
//     if (product && size) {
//       setQuantities(prevQuantities => ({
//         ...prevQuantities,
//         [product]: {
//           ...prevQuantities[product],
//           [size]: newQuantity
//         }
//       }));

//       const docRef = doc(db, 'inventory', product);
//       updateDoc(docRef, { [size]: newQuantity }).catch(error => {
//         console.error("Error updating document:", error);
//       });
//     }
//   };

//   const handleAddProduct = async () => {
//     if (newProduct) {
//       await setDoc(doc(db, 'inventory', newProduct), newQuantities);
//       setNewProduct("");
//       setNewQuantities({xs: 0, s: 0, m: 0, l: 0, xl: 0, xxl: 0 });
//     }
//   };

//   if (loading) return <div>Loading...</div>;

//   return (
//     <div className="manage-tshirt-quantities">
//     <h1>Manage T-Shirt Quantities</h1>
//     <div className="add-product-section">
//       <h2>Add New Product</h2>
//       <input
//         type="text"
//         className="new-product-input"
//         placeholder="Product Name"
//         value={newProduct}
//         onChange={(e) => setNewProduct(e.target.value)}
//       />
//       <div className="sizes-section">
//         {["xs", "s", "m", "l", "xl", "xxl"].map(size => (
//           <div key={size} className="size-input-group">
//             <label className="size-label">{size.toUpperCase()}:</label>
//             <input
//               type="number"
//               className="size-input"
//               value={newQuantities[size]}
//               onChange={(e) => setNewQuantities({ ...newQuantities, [size]: parseInt(e.target.value, 10) })}
//             />
//           </div>
//         ))}
//       </div>
//       <button className="add-product-button" onClick={handleAddProduct}>Add Product</button>
//     </div>
//     <div className="product-list">
//       {Object.keys(quantities).map(product => (
//         <div key={product} className="product-section">
//           <h3>{product}</h3>
//           <div className="sizes-section">
//             {["xs", "s", "m", "l", "xl", "xxl"].map(size => (
//               <div key={size} className="size-input-group">
//                 <label className="size-label">{size.toUpperCase()}:</label>
//                 <input
//                   type="number"
//                   className="size-input"
//                   value={quantities[product][size]}
//                   onChange={(e) => handleQuantityChange(product, size, parseInt(e.target.value, 10))}
//                 />
//               </div>
//             ))}
//           </div>
//         </div>
//       ))}
//     </div>
//   </div>
// );
// };

// export default Inventory;

