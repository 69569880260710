import React, { useEffect, useState } from 'react';
import { db } from '../firebase'; // Make sure to import the Firebase config
import { collection, getDocs } from 'firebase/firestore';

const BrandActivity = () => {
  const [users, setUsers] = useState([]); // State to store shop data

  useEffect(() => {
    // Fetching shops data from Firestore
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const userSnapshot = await getDocs(usersCollection); // Get all documents in the shops collection
        const userList = userSnapshot.docs.map(doc => ({
          id: doc.id, // You can also store the document ID
          brandName: doc.data().brandName,
          phoneNumber: doc.data().phoneNumber,
          emailAddress: doc.data().emailAddress,
          shop: doc.data().shop || 'NA',
          profileUpdatedAt: doc.data().profileUpdatedAt.toDate().toLocaleDateString(),
        }));
        setUsers(userList); // Store the fetched data in state
      } catch (error) {
        console.error("Error fetching shops data: ", error);
      }
    };

    fetchUsers();
  }, []); // Empty dependency array ensures this runs only once after component mounts

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2 style={{ textAlign: 'center' }}>Brand Activity</h2>
      <div style={{ overflowX: 'auto' }}>
        {users.length > 0 ? (
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
            <thead>
              <tr>
                <th style={{ padding: '10px', border: '1px solid #ddd', backgroundColor: '#f4f4f4' }}>Brand Name</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', backgroundColor: '#f4f4f4' }}>Phone Number</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', backgroundColor: '#f4f4f4' }}>Email Address</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', backgroundColor: '#f4f4f4' }}>Shop</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', backgroundColor: '#f4f4f4' }}>Profile Updated On</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id} style={{ backgroundColor: '#fff' }}>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{user.brandName}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{user.phoneNumber}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{user.emailAddress}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{user.shop || 'NA'}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{user.profileUpdatedAt || 'NA'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No users found</p>
        )}
      </div>
    </div>
  );
};

export default BrandActivity;
