import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style/Transaction.css'

const Transactions = () => {
    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [transactions, setTransactions] = useState([]);

    // Fetch all brands and their wallet details
    const fetchBrands = async () => {
        setLoading(true);
        try {
            const usersRef = collection(db, 'users');
            const usersSnapshot = await getDocs(usersRef);

            const brandDetails = [];

            for (const userDoc of usersSnapshot.docs) {
                const userId = userDoc.id;
                const walletRef = doc(db, 'users', userId, 'wallet', 'walletDetails');
                const walletSnap = await getDoc(walletRef);

                if (walletSnap.exists()) {
                    const walletData = walletSnap.data();
                    brandDetails.push({
                        userId,
                        brandName: userDoc.data().brandName, // Assuming 'brandName' exists in the 'users' collection
                        balance: walletData.balance,
                        lastUpdated: walletData.lastUpdated.toDate().toLocaleString(),
                    });
                }
            }

            setBrands(brandDetails);
        } catch (error) {
            console.error('Error fetching brands:', error);
            toast.error('Failed to fetch brands. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Fetch transactions for a selected brand
    const fetchTransactions = async (userId) => {
        setLoading(true);
        try {
            const transactionsCollection = collection(db, 'users', userId, 'wallet', 'walletDetails', 'transactions');
            const transactionsSnapshot = await getDocs(query(transactionsCollection, orderBy('timestamp', 'desc')));

            const allTransactions = transactionsSnapshot.docs.map((doc) => ({
                transactionId: doc.id,
                ...doc.data(),
            }));

            setTransactions(allTransactions);
        } catch (error) {
            console.error('Error fetching transactions:', error);
            toast.error('Failed to fetch transactions. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Handle the click of "Details" to show the brand's transaction history
    const handleDetailsClick = (userId) => {
        setSelectedBrand(userId);
        fetchTransactions(userId);
    };

    useEffect(() => {
        fetchBrands();
    }, []);

    return (
        <div className="transactions-page">
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar />
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    {!selectedBrand ? (
                        <div>
                            <h2>Brands Overview</h2>
                            <table className="brands-table">
                                <thead>
                                    <tr>
                                        <th>Brand Name</th>
                                        <th>Balance (₹)</th>
                                        <th>Last Updated</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {brands.length > 0 ? (
                                        brands.map((brand) => (
                                            <tr key={brand.userId}>
                                                <td>{brand.brandName}</td>
                                                <td>{brand.balance.toFixed(2)}</td>
                                                <td>{brand.lastUpdated}</td>
                                                <td>
                                                    <button onClick={() => handleDetailsClick(brand.userId)}>
                                                        View Details
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4">No brands found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            <h2>Transaction Details for {brands.find(brand => brand.userId === selectedBrand)?.brandName}</h2>
                            <button onClick={() => setSelectedBrand(null)}>Back to Overview</button>
                            <table className="transactions-table">
                                <thead>
                                    <tr>
                                        <th>Transaction Type</th>
                                        <th>Amount (₹)</th>
                                        <th>Balance Before (₹)</th>
                                        <th>Balance After (₹)</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactions.length > 0 ? (
                                        transactions.map((transaction) => (
                                            <tr key={transaction.transactionId}>
                                                <td>{transaction.type}</td>
                                                <td>{transaction.amount !== undefined && transaction.amount !== null ? transaction.amount.toFixed(2) : 'NA'}</td>
                                                <td>{transaction.balanceBefore !== undefined && transaction.balanceBefore !== null ? transaction.balanceBefore.toFixed(2) : 'NA'}</td>
                                                <td>{transaction.balanceAfter !== undefined && transaction.balanceAfter !== null ? transaction.balanceAfter.toFixed(2) : 'NA'}</td>
                                                <td>{new Date(transaction.timestamp.seconds * 1000).toLocaleString()}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5">No transactions found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Transactions;
