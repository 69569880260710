import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

const Aov = () => {
    const [averageOrderValue, setAverageOrderValue] = useState(0);
    const [loading, setLoading] = useState(true);

    // Fetch all orders and calculate the average UniteeTotalPrice
    const fetchOrdersWithUniteeTotalPrice = async () => {
        try {
            setLoading(true);
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const allUniteeTotalPrices = [];

            for (const userDoc of usersSnapshot.docs) {
                const ordersCollection = collection(db, 'users', userDoc.id, 'orders');
                const ordersSnapshot = await getDocs(ordersCollection);

                // Process each order to get its UniteeTotalPrice
                for (const orderDoc of ordersSnapshot.docs) {
                    const orderData = orderDoc.data();
                    if (orderData.UniteeTotalPrice) {
                        allUniteeTotalPrices.push(orderData.UniteeTotalPrice); // Push UniteeTotalPrice to the array
                    }
                }
            }

            return allUniteeTotalPrices;
        } catch (error) {
            console.error("Error fetching orders with UniteeTotalPrice:", error);
            return [];
        } finally {
            setLoading(false);
        }
    };

    // Calculate AOV based on fetched UniteeTotalPrices
    const calculateAOV = (uniteeTotalPrices) => {
        const totalRevenue = uniteeTotalPrices.reduce((sum, price) => sum + price, 0);
        const aov = totalRevenue / (uniteeTotalPrices.length || 1); // Avoid divide by zero
        setAverageOrderValue(aov);
    };

    // Load and calculate AOV
    useEffect(() => {
        const loadAndCalculateAOV = async () => {
            const uniteeTotalPrices = await fetchOrdersWithUniteeTotalPrice();
            calculateAOV(uniteeTotalPrices);
        };

        loadAndCalculateAOV();
    }, []);

    return (
        <div className="average-order-value-page">
            <div className="content-container">
                <div className="main-content">
                    <h1>Average Order Value</h1>
                    {loading ? (
                        <p>Loading data...</p>
                    ) : (
                        <div>
                            <p>Average Order Value: ₹{averageOrderValue.toFixed(2)}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Aov;
